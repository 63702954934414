export enum ProductSaleStatusEnum {
    Edit = -1,
    saleOut = 0,
    sale = 1
}

export const ProductSaleStatusDescEnum = {
    "-1": "编辑中",
    "0": "已下架",
    "1": "已上架"
};

export type ProductSaleStatusDescType = keyof typeof ProductSaleStatusDescEnum;
